import {api} from '../../shared/services/backend-api'
import downloadFile from '../../shared/utils/donwloadFile'
import getFileHeaders from '../../shared/utils/getFileHeaders'
import i18n from '../../plugins/i18n'
import dayjs from 'dayjs';

const RESOURCE = 'users'

const params = {
  page: 1,
  perPage: 10_000
}

const fetchPaymentHistory = async (userID) => {
  const {data} = await api.get(`${RESOURCE}/${userID}/payment-history`, {
    params
  })
  
  return data.data
}

const fetchSubscriptions = async (userID) => {
  const {data} = await api.get(`${RESOURCE}/${userID}/subscriptions`, {
    params
  })
  
  const response = data.data.map(
    ({children: supportedChildren, ...rest}) => {
      return {
        supportedChildren,
        ...rest
      }
    }
  )
  
  return response
}

const cancelSubscription = (subscriptionID) => {
  return api.post(`orders/${subscriptionID}/cancel`)
}

const fetchAnnualReport = async (userID, year) => {
  const payload = {
    year
  }
  const { data } = await api.get(`${RESOURCE}/${userID}/finances`, {
    params: {
      ...payload
    }
  })
  return data
}

const fetchAnnualReportFile = async (userID, year) => {
  const {data, headers} = await api.get(`reports/${userID}/annual`, {
    responseType: 'blob',
    params: {
      year,
    }
  })
  const FILE_EXTENSION = 'pdf'
  const filename = i18n.t('page_profile_orders.annual_report.filename', {
    date: dayjs().format('DD.MM.YYYY; HH.mm.ss'),
    extension: FILE_EXTENSION
  })
  downloadFile({...getFileHeaders(headers), data, filename})
}

export {
  fetchPaymentHistory,
  fetchSubscriptions,
  cancelSubscription,
  fetchAnnualReport,
  fetchAnnualReportFile,
}
