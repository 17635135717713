<template>
  <div>
    <ProfileOrdersHistory/>
    <ProfileOrdersSubscriptions/>
    <ProfileAnnualReport/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

import {USER_LOGIN_ROUTE_NAME} from '../../shared/constants/app-routes-names'
import ProfileOrdersHistory from './profile-orders-history/ProfileOrdersHistory'
import ProfileOrdersSubscriptions from './profile-orders-subscriptions/ProfileOrdersSubscriptions'
import ProfileAnnualReport from './profile-annual-report/ProfileAnnualReport'

export default {
  name: 'ProfileOrders',
  components: {ProfileAnnualReport, ProfileOrdersSubscriptions, ProfileOrdersHistory},
  created() {
    if (!this.isLoggedIn) {
      this.$router.push({
        name: USER_LOGIN_ROUTE_NAME,
        params: {
          back: true
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'authState/isLoggedIn'
    }),
  }
}
</script>
