<template>
  <el-card class="mt-1">
    <template #header>
      <h2 class="heading">
        {{ $t('page_profile_orders.payment_history.title') }}
      </h2>
    </template>
    <el-table
        v-loading="isLoading"
        :data="paymentsToShow"
        style="width: 100%"
        @sort-change="customSort"
    >
      <el-table-column
          :width="250"
          sortable="custom"
          prop="unitTitle"
          :label="$t('page_profile_orders.payment_history.table_headers.unit_title')"
      >
        <template #default="{row}">
          <div style="font-size: 0.8em;">
            {{ row.unitTitle }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
          :width="220"
          prop="purpose"
          :label="$t('page_profile_orders.payment_history.table_headers.purpose')"
      >
        <template #default="{row}">
          <div style="font-size: 0.8em;">
            {{ row.purpose }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
          sortable
          prop="action"
          :width="140"
          :label="$t('page_profile_orders.payment_history.table_headers.action')"
      >
        <template slot-scope="scope">
          {{ $t(`page_profile_orders.payment_history.action_types.${scope.row.action}`) }}
        </template>
      </el-table-column>
      <el-table-column
          sortable
          :width="100"
          prop="amount"
          :label="$t('page_profile_orders.payment_history.table_headers.amount')"
      >
        <template slot-scope="scope">
          <div class="text-right">
            {{ $options.moneyFormat(scope.row.amount) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
          :width="100"
          sortable
          prop="date"
          :label="$t('page_profile_orders.payment_history.table_headers.payment_date')"
      >
        <template slot-scope="scope">
          <div class="text-right">
            {{ scope.row.date.slice(0, 10) }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <v-pagination :items="paymentHistoryList" @update:items-to-show="paymentsToShow = $event"/>
  </el-card>
</template>

<script>
import VPagination from '../../../components/ui/v-pagination/VPagination'
import {fetchPaymentHistory} from '../../services/profile-orders-service'
import {tableSort} from '../../../shared/utils/tableSort'
import {moneyFormat} from '../../../shared/utils/moneyFormat'
import {mapState} from 'vuex'

export default {
  name: 'ProfileOrdersHistory',
  components: {VPagination},
  moneyFormat,
  data() {
    return {
      isLoading: false,
      paymentHistoryList: [],
      paymentsToShow: []
    }
  },
  created() {
    this.fetchPaymentHistory()
  },
  methods: {
    customSort(sortInfo) {
      this.paymentHistoryList = tableSort(this.paymentHistoryList, sortInfo)
    },
    fetchPaymentHistory() {
      this.isLoading = true
      fetchPaymentHistory(this.user.uuid)
          .then((res) => {
            this.paymentHistoryList = res
          })
          .finally(() => {
            this.isLoading = false
          })
          .catch(e => {
            this.$message({
              type: 'error',
              message: `${e.data}`
            })
          })
    },
  },
  computed: {
    ...mapState('authState', {
      user: state => state.user,
    }),
  }
}
</script>
<style>
.text-right {
  text-align: right !important;
}
</style>
