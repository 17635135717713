<template>
  <el-card class="mt-1">
    <template #header>
      <h2 class="heading">
        {{ $t('page_profile_orders.subscriptions_history.title') }}
      </h2>
    </template>
    <el-table
        @sort-change="customSort"
        v-loading="isLoading"
        :data="subscriptionsToShow"
        style="width: 100%"
    >
      <el-table-column
          sortable
          prop="dateStart"
          :width="100"
          :label="$t('page_profile_orders.subscriptions_history.table_headers.date_start')"
      >
        <template slot-scope="scope">
          {{ scope.row.dateStart.slice(0, 10) }}
        </template>
      </el-table-column>
      <el-table-column
          :width="200"
          sortable="custom"
          prop="unitTitle"
          :label="$t('page_profile_orders.subscriptions_history.table_headers.unit_title')"
      >
        <template #default="{row}">
          <div class="font--small">
            {{ row.unitTitle }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
          :width="70"
          prop="supportedChildren"
          :label="$t('page_profile_orders.subscriptions_history.table_headers.supported_children')"
      >
        <template #default="{row}">
          <div class="text-center">
            {{ row.unitType === $options.UNIT_TYPE_FAMILY ? '-' : row.supportedChildren }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
          sortable
          :width="130"
          prop="monthlyCost"
          :label="$t('page_profile_orders.subscriptions_history.table_headers.monthly_cost')"
      >
        <template slot-scope="{row}">
          <div class="text-right">
            {{ moneyFormat(row.monthlyCost * row.supportedChildren) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
          sortable
          :width="100"
          prop="dateEnd"
          :label="$t('page_profile_orders.subscriptions_history.table_headers.date_end')"
      >
        <template slot-scope="{row}">
          <div :class="{'font--small': !row.dateEnd}">
            {{ row.dateEnd ? row.dateEnd.slice(0, 10) : $t('page_profile_orders.subscriptions_history.endless_subscription') }}
          </div>
        </template>
      </el-table-column>
      <el-table-column :width="110">
        <template slot-scope="{row}">
            <v-button v-if="row.kindOfSubscription === $options.SUBSCRIPTION_TYPE_ONE_TIME"
                      @click="goToRenew(row.subscriptionId)"
                      type="primary"
            >
              {{ $t('page_profile_orders.subscriptions_history.btn_renew') }}
            </v-button>
        </template>
      </el-table-column>
      <el-table-column :width="110">
        <template slot-scope="{row}">
          <el-popconfirm
              @confirm="handleUnsubscribe(row.subscriptionId)"
              :confirm-button-text="$t('page_profile_orders.subscriptions_history.confirm_modal.btn_confirm')"
              :cancel-button-text="$t('page_profile_orders.subscriptions_history.confirm_modal.btn_cancel')"
              icon="el-icon-info"
              :title="$t('page_profile_orders.subscriptions_history.confirm_modal.confirm_text')"
              icon-color="red"
          >
            <template #reference>
              <v-button>
                {{ $t('page_profile_orders.subscriptions_history.btn_cancel') }}
              </v-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <v-pagination :items="subscriptionList" @update:items-to-show="subscriptionsToShow = $event"/>
  </el-card>
</template>

<script>
import VPagination from '../../../components/ui/v-pagination/VPagination'
import VButton from '../../../components/ui/v-button/VButton'

import {
  fetchSubscriptions,
  cancelSubscription
} from '../../services/profile-orders-service'

import {moneyFormat} from '../../../shared/utils/moneyFormat'
import {tableSort} from '../../../shared/utils/tableSort'
import {mapState} from 'vuex'

import {RENEW_SUBSCRIPTION_ROUTE_NAME} from '../../../shared/constants/app-routes-names'
import {UNIT_TYPE_FAMILY} from '../../../admin/units/shared/constants/units-constants'


const SUBSCRIPTION_TYPE_ONE_TIME = 'O'

export default {
  name: 'ProfileOrdersSubscriptions',
  components: {VButton, VPagination},
  SUBSCRIPTION_TYPE_ONE_TIME,
  UNIT_TYPE_FAMILY,
  data() {
    return {
      subscriptionList: [],
      subscriptionsToShow: [],
      isLoading: false
    }
  },
  created() {
    this.fetchSubscriptions()
  },
  methods: {
    moneyFormat,
    customSort(sortInfo) {
      this.subscriptionList = tableSort(this.subscriptionList, sortInfo)
    },
    fetchSubscriptions() {
      this.isLoading = true
      fetchSubscriptions(this.user?.uuid)
          .then((res) => {
            this.subscriptionList = res
          })
          .finally(() => {
            this.isLoading = false
          })
          .catch(e => {
            this.$message({
              type: 'error',
              message: `${e.data}`
            })
          })
    },
    goToRenew(subscriptionID) {
      this.$router.push({
        name: RENEW_SUBSCRIPTION_ROUTE_NAME,
        params: {
          subscriptionID
        }
      })
    },
    handleUnsubscribe(subscriptionID) {
      this.isLoading = true
      cancelSubscription(subscriptionID)
          .then(() => {
            this.fetchSubscriptions()
            this.$message({
              type: 'success',
              message: `${
                  this.$t('page_profile_orders.subscriptions_history.messages.cancel_success')
              }`,
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: `${
                  this.$t('page_profile_orders.subscriptions_history.messages.cancel_error')
              }`,
            })
          })
          .finally(() => {
            this.isLoading = false
          })
    }
  },
  computed: {
    ...mapState('authState', {
      user: state => state.user,
    }),
  }
}
</script>

