<template>
  <el-card class="mt-1">
    <template #header>
      <h2 class="heading">
        {{ $t('page_profile_orders.annual_report.title') }}
      </h2>
    </template>
    <v-date-picker v-model="year"
                   input-type="year"
                   format="yyyy"
                   value-format="yyyy"
                   @input="fetchReport"
                   :clearable="false"
                   :picker-options="{ disabledDate }"
    />
    <div class="float-right d-flex align-center">
      <v-button class="mr-2" @click="downloadAnnualReport" type="primary" :disabled="disableDownloadButton">
        {{ $t('page_profile_orders.annual_report.download_report_button') }}
      </v-button>
      <el-tooltip placement="top">
        <v-icon name="info" size="1.5rem" color="#3f9eff"/>
        <template #content>
          {{ $t('page_profile_orders.annual_report.download_report_tooltip') }}
        </template>
      </el-tooltip>
    </div>
    <el-table
        v-loading="isLoading"
        :data="annualReportItemsToShow"
        style="width: 100%"
        @sort-change="customSort"
    >
      <el-table-column
          sortable="custom"
          prop="unitTitle"
          :label="$t('page_profile_orders.annual_report.table_headers.unit_title')"
      >
        <template #default="{row, $index}">
          <span :class="applySummaryClass($index)">
            {{ $index === 0 ? 'TOTAL' : row.unitTitle }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
          :width="130"
          sortable="custom"
          prop="totalMoney"
          :label="$t('page_profile_orders.annual_report.table_headers.orders_sum')"
      >
        <template #default="{row, $index}">
          <div :class="applySummaryClass($index)" class="text-right">
            {{ formatMoneyContent(row.totalMoney) }}
          </div>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
import {fetchAnnualReport, fetchAnnualReportFile} from '../../services/profile-orders-service'
import {mapState} from 'vuex'
import VDatePicker from '../../../components/ui/v-date-picker/VDatePicker'
import {moneyFormat} from '../../../shared/utils/moneyFormat'
import {tableSort} from '../../../shared/utils/tableSort'
import {tableSummaryCounter} from '../../../shared/utils/tableSummaryCounter'
import VButton from '../../../components/ui/v-button/VButton'
import VIcon from '../../../components/ui/v-icon/VIcon'

export default {
  name: 'ProfileAnnualReport',
  components: {VIcon, VButton, VDatePicker},
  data() {
    return {
      annualReportItems: [],
      isLoading: false,
      year: '2021'
    }
  },
  created() {
    this.fetchReport()
  },
  methods: {
    downloadAnnualReport() {
      fetchAnnualReportFile(this.userId, this.year)
    },
    disabledDate(d) {
      const date = new Date()
      return d.getFullYear() >= date.getFullYear()
    },
    customSort(sortInfo) {
      this.annualReportItems = tableSort(this.annualReportItems, sortInfo)
    },
    formatMoneyContent(val) {
      return moneyFormat(val)
    },
    fetchReport() {
      this.isLoading = true
      fetchAnnualReport(this.userId, this.year)
          .then((res) => {
            this.annualReportItems = res
          })
          .catch((e) => {
            this.$message({
              type: 'error',
              message: this.$t(`alerts.common_error`, {status: e.status})
            })
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    applySummaryClass(index) {
      return {'font-bold': index === 0}
    },
  },
  computed: {
    ...mapState('authState', {
      userId: state => state.user.uuid
    }),
    disableDownloadButton() {
      const selectedDate = new Date(this.year)
      const today = new Date()
      const prevYear = today.getFullYear() - 1
      const ALLOW_FROM = new Date(today.getFullYear(), 0, 21)
      return today < ALLOW_FROM && selectedDate.getFullYear() === prevYear || this.isTableEmpty
    },
    isTableEmpty() {
      return this.annualReportItems.length === 0
    },
    tableSummary() {
      return tableSummaryCounter(this.annualReportItems)
    },
    annualReportItemsToShow() {
      const items = []
      if (this.tableSummary) {
        items.unshift(this.tableSummary)
      }
      return [...items, ...this.annualReportItems]
    }
  }
}
</script>

