var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',{staticClass:"mt-1",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',{staticClass:"heading"},[_vm._v(" "+_vm._s(_vm.$t('page_profile_orders.subscriptions_history.title'))+" ")])]},proxy:true}])},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.subscriptionsToShow},on:{"sort-change":_vm.customSort}},[_c('el-table-column',{attrs:{"sortable":"","prop":"dateStart","width":100,"label":_vm.$t('page_profile_orders.subscriptions_history.table_headers.date_start')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.dateStart.slice(0, 10))+" ")]}}])}),_c('el-table-column',{attrs:{"width":200,"sortable":"custom","prop":"unitTitle","label":_vm.$t('page_profile_orders.subscriptions_history.table_headers.unit_title')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"font--small"},[_vm._v(" "+_vm._s(row.unitTitle)+" ")])]}}])}),_c('el-table-column',{attrs:{"width":70,"prop":"supportedChildren","label":_vm.$t('page_profile_orders.subscriptions_history.table_headers.supported_children')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(row.unitType === _vm.$options.UNIT_TYPE_FAMILY ? '-' : row.supportedChildren)+" ")])]}}])}),_c('el-table-column',{attrs:{"sortable":"","width":130,"prop":"monthlyCost","label":_vm.$t('page_profile_orders.subscriptions_history.table_headers.monthly_cost')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.moneyFormat(row.monthlyCost * row.supportedChildren))+" ")])]}}])}),_c('el-table-column',{attrs:{"sortable":"","width":100,"prop":"dateEnd","label":_vm.$t('page_profile_orders.subscriptions_history.table_headers.date_end')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{class:{'font--small': !row.dateEnd}},[_vm._v(" "+_vm._s(row.dateEnd ? row.dateEnd.slice(0, 10) : _vm.$t('page_profile_orders.subscriptions_history.endless_subscription'))+" ")])]}}])}),_c('el-table-column',{attrs:{"width":110},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.kindOfSubscription === _vm.$options.SUBSCRIPTION_TYPE_ONE_TIME)?_c('v-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.goToRenew(row.subscriptionId)}}},[_vm._v(" "+_vm._s(_vm.$t('page_profile_orders.subscriptions_history.btn_renew'))+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"width":110},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-popconfirm',{attrs:{"confirm-button-text":_vm.$t('page_profile_orders.subscriptions_history.confirm_modal.btn_confirm'),"cancel-button-text":_vm.$t('page_profile_orders.subscriptions_history.confirm_modal.btn_cancel'),"icon":"el-icon-info","title":_vm.$t('page_profile_orders.subscriptions_history.confirm_modal.confirm_text'),"icon-color":"red"},on:{"confirm":function($event){return _vm.handleUnsubscribe(row.subscriptionId)}},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('v-button',[_vm._v(" "+_vm._s(_vm.$t('page_profile_orders.subscriptions_history.btn_cancel'))+" ")])]},proxy:true}],null,true)})]}}])})],1),_c('v-pagination',{attrs:{"items":_vm.subscriptionList},on:{"update:items-to-show":function($event){_vm.subscriptionsToShow = $event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }